<template>
	<v-card>
		<v-toolbar color="primary">
			<v-toolbar-title class="white--text"
				>Ficha Cliente {{ identidad }}</v-toolbar-title
			>
			<v-spacer></v-spacer>
			<v-btn color="white" icon @click="$emit('close')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>

		<v-container class="pa-2 mt-8">
			<v-row>
				<v-col cols="12" md="4">
					<v-text-field
						hide-details
						outlined
						readonly
						:value="datosCliente.Identidad"
						label="DNI/CIF/NIE"
					/>
				</v-col>
				<v-col cols="12" md="4" v-if="!datosCliente.RazonSocial">
					<v-text-field
						hide-details
						outlined
						readonly
						:value="
							[
								datosCliente.Nombre,
								datosCliente.Apellido1,
								datosCliente.Apellido2,
							]
								.filter((v) => !!v)
								.join(' ')
						"
						label="Nombre Completo"
					/>
				</v-col>
				<v-col cols="12" md="4" v-else>
					<v-text-field
						hide-details
						outlined
						readonly
						:value="datosCliente.RazonSocial"
						label="Razón social"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field
						hide-details
						outlined
						readonly
						:value="`${datosCliente.TextoCiudad}, ${datosCliente.CodPostal}`"
						label="Ciudad"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field
						hide-details
						outlined
						readonly
						:value="datosCliente.NombreCalle"
						label="Calle"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field
						hide-details
						outlined
						readonly
						:value="datosCliente.Numero"
						label="Numero"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field
						hide-details
						outlined
						readonly
						:value="datosCliente.Aclarador"
						label="Aclarador"
					/>
				</v-col>
				<v-col cols="12" md="4" v-if="datosCliente.Representante">
					<v-text-field
						hide-details
						outlined
						readonly
						:value="datosCliente.IdentificadorRep"
						label="DNI Representante"
					/>
				</v-col>
				<v-col cols="12" md="4" v-if="datosCliente.Representante">
					<v-text-field
						hide-details
						outlined
						readonly
						:value="datosCliente.Representante"
						label="Representante"
					/>
				</v-col>

				<v-col cols="12">
					<v-autocomplete
						outlined
						label="CUPS"
						:items="cupsItems"
						v-model="cups"
						hint="Selecciona un CUPS"
						persistent-hint
					/>

					<Incidencias v-if="cups" :idCliente="idCliente" :cups="cups" />

					<div class="text-center" v-else>Selecciona un CUPS</div>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import { processToken, perColumnFilter, parseDate } from "@/utils/index.js";

export default {
	components: {
		Incidencias: () => import("@/views/Incidencias.vue"),
	},
	props: {
		idCliente: String,
		identidad: String,
	},
	data() {
		return {
			cups: null,
			tipo: null,
			incidencia: null,
			mensaje: "",
			loading: true,

			cupsItems: [],
			datosCliente: {},

			incidencias: [],
			inlineFilters: {},
			incidenciasHeader: [
				{ text: "No.", value: "id" },
				{ text: "DNI cliente", value: "dniCliente" },
				{ text: "CUPS cliente", value: "cupsCliente" },
				{ text: "Usuario", value: "idUsuario" },
				{ text: "Fecha", value: "fecha", dataType: "date" },
				{ text: "Estado", value: "estado" },
				{
					text: "Acciones",
					value: "acciones",
					sortable: false,
					filterable: false,
				},
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),
		};
	},
	mounted() {
		if (!this.idCliente) return;
		axios({
			method: "GET",
			url: `${process.env.VUE_APP_API_URL}/clientes.php`,
			params: {
				token: this.$store.getters.getToken,
				IdCliente: this.idCliente,
			},
		}).then((res) => {
			this.datosCliente = res.data[0];
		});

		axios({
			method: "GET",
			url: `${process.env.VUE_APP_API_URL}/cups.php`,
			params: {
				token: this.$store.getters.getToken,
				IdCliente: this.idCliente,
			},
		}).then(({ data }) => {
			this.cupsItems = data.map((c) => c.CodigoCups);
			// this.cups = this.cupsItems[0];
		});
	},
	watch: {
		cups() {
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_API_URL}/incidencias.php`,
				params: {
					cups : this.cups,
					token: this.$store.getters.getToken
				}
			}).then(({ data }) => {
				console.log(data);
				this.incidencias = data;
				this.loading = false;
			});
		},
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	background: #f1f1f1 !important;
}
</style>